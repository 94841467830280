import { faTable } from '@fortawesome/pro-light-svg-icons';
import { faAddressCard, faFilePdf, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, Tooltip } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  ButtonBlue,
  ButtonBlueLight,
  ButtonFloating,
  FontIcon,
  GenericListContainer,
  GenericListFilters,
  ReactTour,
  TitlePage,
  Wrapper
} from 'components';
import { formatDuration, intervalToDuration } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useEffectAfterRender, useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { InterventionCategory } from 'models';
import { FormFrontView } from 'models/detail/FormFrontView';
import { InterventionEntry } from 'models/list/InterventionList';
import { InterventionFilter } from 'models/request/list/InterventionFilter';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Media from 'react-media';
import { useLocation } from 'react-router-dom';
import { InterventionsService } from 'services';
import { APPLICATION_ROLES, StatusList } from 'utils/constants';
import {
  DocumentHelper,
  FilterHelper,
  IndexedDBHelper,
  InterventionHelper,
  StorageHelper,
  translate,
  UserHelper
} from 'utils/helpers';
import { KEYCLOAK_STORE } from 'utils/helpers/IndexedDBHelper';
import { FilterItem } from 'utils/types/FilterItem';
import { InterventionListFilters } from './InterventionListFilters';
import { ListCardMode } from './ListCardMode';
import { ListTableMode } from './ListTableMode';

const INTERVENTION_SIZE_IN_MB = 20;

const EXPORT_MAIL_SIZE = 1500;

export const InterventionList = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const { open } = useModal();
  const { userStore } = useStores();
  const location = useLocation();

  const filterKey = 'interventionList';
  const locationState: any = location.state;
  const historyFilter: FilterItem<InterventionFilter>[] = (locationState && locationState.filters);
  const defaultFilters: FilterItem<InterventionFilter>[] = [{
    key: 'statuses',
    listValue: [{
      value: 'READY',
      label: translate('interventionStatus.READY')
    }, {
      value: 'PENDING',
      label: translate('interventionStatus.PENDING')
    }, {
      value: 'ONGOING',
      label: translate('interventionStatus.ONGOING')
    }]
  }, {
    key: 'period',
    label: translate('button.thisMonth'),
    stringValue: 'MONTH'
  }];

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isTableMode, setIsTableMode] = useState(UserHelper.hasAccessRight(
    [APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.INTERVENTION_MANAGER]
  ));
  const [interventionList, setInterventionList] = useState<InterventionEntry[]>([]);
  const [listSize, setListSize] = useState(0);
  const [interventionListStored, setInterventionListStored] = useState<FormFrontView[]>([]);
  const [storageUsage, setStorageUsage] = useState<{
    interventionsRemaining: number,
    interventionsStored: number,
    percentageUsed: string
  }>(null);
  const [lastAutomaticDownload, setLastAutomaticDownload] = useState<string>(undefined);
  const [INTERVENTION_STATUS, setInterventionStatus] = useState<StatusList>(null);
  const [EXPORTABLE_STATUS, setExportableStatus] = useState<string[]>([]);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(historyFilter);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const getInterventionList = useCallback(({ pageChange = false } = {}) => {
    async function getInterventionListRefresh() {
      setIsLoading(true);
      await InterventionsService.getList(FilterHelper.buildFilterForm(filters, search), currentPage)
        .then((response) => {
          if (response) {
            let allInterventions = response.content;
            if (pageChange && currentPage > 1) {
              allInterventions = [].concat(...interventionList, response.content);
            }
            setInterventionList(allInterventions);
            setMaxPage(response.totalPages);
            setListSize(response.totalElements);
          }
        }).catch(error => !userStore.isOffline && enqueueSnackbar(error, { variant: 'error' }))
        .finally(() => setIsLoading(false));
    }

    if (!userStore.isOffline) {
      getInterventionListRefresh();
    }

    InterventionHelper.getStoredInterventionFormList().then((intList) => {
      const reversedInterventions = intList.reverse();
      setInterventionListStored(reversedInterventions);

      StorageHelper.getUsedQuota().then((storage) => {
        const capacityRemaining = StorageHelper.formatMBToNumber(storage.remaining / INTERVENTION_SIZE_IN_MB);
        setStorageUsage({
          interventionsStored: intList.length,
          interventionsRemaining: capacityRemaining,
          percentageUsed: Number((intList.length * 100) / capacityRemaining).toFixed(2)
        });
      });
    });
  }, [currentPage, enqueueSnackbar, search, filters, interventionList, userStore.isOffline]);

  const reloadList = () => {
    if (currentPage === 1) {
      getInterventionList({ pageChange: false });
    } else {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (userStore.isOffline) {
      reloadList();
    }
    // eslint-disable-next-line
  }, [userStore.isOffline]);

  useEffectAfterRender(() => {
    reloadList();
    // eslint-disable-next-line
  }, [filters, search]);

  useEffectAfterRender(() => {
    getInterventionList({ pageChange: currentPage !== 1 });
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    userStore.refreshInterventions && getInterventionList({ pageChange: false });
    userStore.setRefreshInterventions(false);
    // eslint-disable-next-line
  }, [userStore.refreshInterventions]);

  useEffect(() => {
    isMobile && setIsTableMode(false);

    IndexedDBHelper.getData({
      store: KEYCLOAK_STORE,
      key: `lastAutomaticInterventionDownload_${userStore.currentUser?.accountId}`
    }).then((date) => {
      if (date) {
        const timeSince = formatDuration(intervalToDuration({
          start: date,
          end: new Date()
        }), { locale: fr });

        setLastAutomaticDownload(timeSince);
      }
    });

    InterventionHelper.loadInterventionStatuses().then((IS) => {
      setInterventionStatus(IS);
      setExportableStatus([
        IS.TO_SIGN.key,
        IS.FINISHED.key,
        IS.TO_VALIDATE.key,
        IS.FINISHED.key
      ]);
    });
  }, [userStore.currentUser]);

  const buildInterventionCategory: (
    intervention: FormFrontView
  ) => InterventionCategory = intervention => ({
    assembly: intervention.assembly,
    commissioning: intervention.commissioning,
    dismantling: intervention.dismantling,
    maintenance: intervention.maintenance,
    modification: intervention.modification,
    nonPeriodicControl: intervention.nonPeriodicControl,
    other: intervention.other,
    periodicControl: intervention.periodicControl
  });

  const filterStoredInterventionOnSearch: (
    form: FormFrontView
  ) => boolean = (form) => {
    const searchLowercase = search.toLowerCase();
    if (!searchLowercase) {
      return true;
    }

    return (
      (form.equipmentOwner && (
        // Filter on owner first & last name
        (form.equipmentOwner.firstName && form.equipmentOwner.firstName.toLowerCase().indexOf(searchLowercase) > -1)
        || (form.equipmentOwner.lastName && form.equipmentOwner.lastName.toLowerCase().indexOf(searchLowercase) > -1)
      )) || (
        // Filter on fileNumber
        (form.fileNumber && form.fileNumber.toLowerCase().indexOf(searchLowercase) > -1)
      ) || (form.equipment && (
        // Filter on equipment modal / serialNumber / fluid
        (form.equipment.model && form.equipment.model.toLowerCase().indexOf(searchLowercase) > -1)
        || (form.equipment.serialNumber && form.equipment.serialNumber.toLowerCase().indexOf(searchLowercase) > -1)
        || (form.equipment.fluid && form.equipment.fluid.label.toLowerCase().indexOf(searchLowercase) > -1)
        || (form.equipment.internalIdentification && form.equipment.internalIdentification.toLowerCase().indexOf(searchLowercase) > -1)
      )) || (form.equipmentOwner?.address && (
        // Filter on owner address
        (form.equipmentOwner.address.address1 && form.equipmentOwner.address.address1.toLowerCase().indexOf(searchLowercase) > -1)
        || (form.equipmentOwner.address.postalCode && form.equipmentOwner.address.postalCode.toLowerCase().indexOf(searchLowercase) > -1)
        || (form.equipmentOwner.address.city && form.equipmentOwner.address.city.toLowerCase().indexOf(searchLowercase) > -1)
      ))
    );
  };

  const displayModalCreateIntervention = () => open({
    type: 'CREATE_INTERVENTION'
  });

  const handleChangeDisplayMode = (e, value) => {
    if (value === null) return;
    setIsTableMode(value);
  };

  const deleteAction = useCallback((e, hashId: string, identifier: string, isFromStorage: boolean) => {
    e.preventDefault();
    e.stopPropagation();

    open({
      type: 'WARNING',
      text: translate('warnings.intervention.delete'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => {
        // Delete the intervention in the backend if it was planified
        if (!isFromStorage) {
          InterventionsService.deleteIntervention(hashId)
            .then(() => {
              enqueueSnackbar(translate('confirms.intervention.delete'), { variant: 'success' });
              if (currentPage > 1) setCurrentPage(1);
              else getInterventionList();
            });
        } else {
          // Delete it from the IndexedDB and reload the list
          InterventionHelper.deleteInterventionStored(hashId, identifier)
            .then(() => {
              enqueueSnackbar(translate('confirms.intervention.delete'), { variant: 'success' });
              if (currentPage > 1) setCurrentPage(1);
              else getInterventionList();
            });
        }
      }
    });
  }, [currentPage, enqueueSnackbar, getInterventionList, open]);

  const loadMore = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setCurrentPage(currentPage + 1);
  }, [isLoading, currentPage]);

  const isExportable: (
    interventionStatus: string
  ) => boolean = useCallback(interventionStatus => !!EXPORTABLE_STATUS.find(status => status === interventionStatus), [EXPORTABLE_STATUS]);

  const exportInterventions = useCallback(() => {
    setIsExporting(true);

    if (Number(listSize) > EXPORT_MAIL_SIZE) {
      const timeout = new Promise(res => {
        setTimeout(() => res(undefined), 1000);
      });
      const controller = new AbortController();
      const { signal } = controller;
      Promise.race([InterventionsService.exportInterventionsByEmail(FilterHelper.buildFilterForm(filters, search), signal), timeout])
        .then(() => {
          enqueueSnackbar(
            translate('confirms.intervention.exportByEmail').replace('<br />', '\n'),
            { variant: 'success', autoHideDuration: 5000 }
          );
          controller.abort();
        })
        .catch((error) => enqueueSnackbar(
          (error.message || error || translate('errors.export')).replace('<br />', '\n'),
          { variant: 'error', autoHideDuration: 5000 }
        ))
        .finally(() => {
          setIsExporting(false);
        });
    } else {
      InterventionsService.exportInterventions(FilterHelper.buildFilterForm(filters, search)).then((response) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = DocumentHelper.getExcelWithBase64(response);
        downloadLink.download = response.name;
        downloadLink.click();
      })
        .catch((error) => enqueueSnackbar(
          (error.message || error || translate('errors.export')).replace('<br />', '\n'),
          { variant: 'error', autoHideDuration: 5000 }
        ))
        .finally(() => setIsExporting(false));
    }
  }, [search, listSize, enqueueSnackbar, filters]);

  const renderCreateButtonMobile = () => (
    <ButtonFloating dataCy="createNewIntervention" onClick={displayModalCreateIntervention}>
      <Fab aria-label="créer une intervention" color="primary">
        <FontIcon fontSize="3rem" icon="icon-create-intervention" />
      </Fab>
    </ButtonFloating>
  );

  const renderExportButton = useCallback(() => (
    <Tooltip disableFocusListener title={translate('pageInterventionList.tooltipExportList')}>
      <span>
        <ButtonBlueLight
                    data-tour="step-intervention-export"
                    disabled={!listSize || isExporting || isLoading}
                    margin="0 1rem 0 0"
                    onClick={exportInterventions}
                >
          <FontAwesomeIcon icon={isExporting ? faSpinner : faFilePdf} spin={isExporting}/>
          {translate('button.export')}
        </ButtonBlueLight>
      </span>
    </Tooltip>
  ), [exportInterventions, isExporting, isLoading, listSize]);

  const renderButtonsDesktop = () => (
    <div className="buttonsContainer">
      {UserHelper.hasAccessRight([
        APPLICATION_ROLES.ADMIN,
        APPLICATION_ROLES.SUPER_ADMIN,
        APPLICATION_ROLES.INTERVENTION_MANAGER,
        APPLICATION_ROLES.STOCK_MANAGER
      ])
        && renderExportButton()}
      {userStore.isViewingInstitution() && UserHelper.hasAccessRightCreateIntervention() && (
        <ButtonBlue
          data-cy="createNewIntervention"
          data-tour="step-intervention-create"
          onClick={displayModalCreateIntervention}
        >
          <FontIcon fontSize="2rem" icon="icon-create-intervention" />
          {translate('button.create')}
        </ButtonBlue>
      )}
    </div>
  );

  const renderButtonsToggleMode = () => (
    <div className="toggleButtonContainer" data-cy="toggleDisplayInterventionList">
      <ToggleButtonGroup
        exclusive
        size="small"
        value={isTableMode}
        onChange={handleChangeDisplayMode}
      >
        <ToggleButton
          aria-label={translate('pageInterventionList.tableMode')}
          value
        >
          <FontAwesomeIcon icon={faTable} />
          {translate('pageInterventionList.tableMode')}
        </ToggleButton>
        <ToggleButton
          aria-label={translate('pageInterventionList.cardMode')}
          value={false}
        >
          <FontAwesomeIcon icon={faAddressCard} />
          {translate('pageInterventionList.cardMode')}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );

  const renderGenericFilters = ({ currentFilters, setCurrentFilters }) => (
    INTERVENTION_STATUS && (
      <InterventionListFilters
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
      />
    )
  );

  return (
    <GenericListContainer>
      <Wrapper>
        <TitlePage>
          <Media
            query="(min-width: 769px)"
            render={renderButtonsToggleMode}
          />
          <span>{translate('pageInterventionList.title')}</span>
          <Media
            query="(min-width: 769px)"
            render={renderButtonsDesktop}
          />
        </TitlePage>
        <GenericListFilters
          ComponentFilter={renderGenericFilters}
          defaultFilters={defaultFilters}
          disabled={false}
          filterKey={filterKey}
          filters={userStore.isOffline ? [] : filters}
          isVisible
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          withSidePanel={!userStore.isOffline}
        />

        {isTableMode ? (
          <ListTableMode
            buildInterventionCategory={buildInterventionCategory}
            currentPage={currentPage}
            deleteAction={deleteAction}
            displayModal={open}
            displayModalCreateIntervention={displayModalCreateIntervention}
            filterStoredInterventionOnSearch={filterStoredInterventionOnSearch}
            INTERVENTION_STATUS={INTERVENTION_STATUS}
            interventionList={userStore.isOffline ? [] : interventionList}
            isExportable={isExportable}
            isLoading={isLoading}
            lastAutomaticDownload={lastAutomaticDownload}
            loadMore={loadMore}
            maxPage={maxPage}
            reloadList={reloadList}
            storageUsage={storageUsage}
            storedInterventionList={interventionListStored}
            total={listSize}
          />
        ) : (
          <ListCardMode
            currentPage={currentPage}
            deleteAction={deleteAction}
            displayModalCreateIntervention={displayModalCreateIntervention}
            filterStoredInterventionOnSearch={filterStoredInterventionOnSearch}
            INTERVENTION_STATUS={INTERVENTION_STATUS}
            interventionList={userStore.isOffline ? [] : interventionList}
            isExportable={isExportable}
            isLoading={isLoading}
            lastAutomaticDownload={lastAutomaticDownload}
            loadMore={loadMore}
            maxPage={maxPage}
            reloadList={reloadList}
            storageUsage={storageUsage}
            storedInterventionList={interventionListStored}
          />
        )}
      </Wrapper>

      {userStore.isViewingInstitution() && UserHelper.hasAccessRightCreateIntervention()
        && (
          <Media
            query="(max-width: 1080px)"
            render={renderCreateButtonMobile}
          />
        )}
      <ReactTour steps={[]} />
    </GenericListContainer>
  );
});
