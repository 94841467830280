import { faFileExport } from '@fortawesome/pro-light-svg-icons';
import {
  faAirConditioner,
  faCheck,
  faEdit,
  faInfoCircle,
  faSpinner,
  faTag,
  faTimes,
  faUserEdit,
  faUserTie
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Fab, IconButton, ListItemText, TableCell
} from '@material-ui/core';
import {
  ButtonBlue,
  ButtonFloating,
  CustomIcon,
  CustomTooltip,
  FlexContainer,
  GenericListContainer,
  GenericListFilters,
  GenericTable,
  SubtitlePage,
  Text,
  TextAddress,
  TextError,
  TitlePage,
  Wrapper
} from 'components';
import { SkeletonMain, SkeletonTableSmall } from 'components/Skeletons';
import { differenceInMonths } from 'date-fns';
import { useEffectAfterRender, useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { EntityView, EquipmentSummaryItem } from 'models';
import { RefrigerationUnitView } from 'models/detail/RefrigerationUnitView';
import { RefrigerationUnitSummaryItem } from 'models/list/RefrigerationUnitSummary';
import { RefrigerationUnitFilter } from 'models/request/list/RefrigerationUnitFilter';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import Media from 'react-media';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { EquipmentOwnersService, EquipmentsService } from 'services';
import shortid from 'shortid';
import styled from 'styled-components';
import { EQUIPMENT_MONTHS_NEXT_CONTROL, ROUTES } from 'utils/constants';
import {
  DocumentHelper, FilterHelper, formatDateString, translate
} from 'utils/helpers';
import { HeaderType } from 'utils/types';
import { FilterItem } from 'utils/types/FilterItem';
import { ToggleValidationButton } from '../../Form/ToggleButtons/ToggleValidationButton';
import { EquipmentListFilters } from './EquipmentListFilters';

const getDateColorDisplay = (date) => {
  if (!date) return 'var(--black)';

  const monthsToGo = differenceInMonths(new Date(date), new Date());

  if (monthsToGo < 0) {
    return 'var(--red)';
  }
  if (monthsToGo < EQUIPMENT_MONTHS_NEXT_CONTROL) {
    return 'var(--orange)';
  }

  return 'var(--black)';
};

const ActivationTemplate = ({
   row, updateEquipmentInList
 }) => {
  const [activatedTool, setActivatedTool] = useState(row?.activated);

  const handleToggleChange = useCallback((e, equipment, value) => {
    const activated = value === 'on';
    e.preventDefault();
    EquipmentsService.toggleActivation(equipment?.id)
        .then(
            () => {
              setActivatedTool(activated);
              enqueueSnackbar(translate('confirms.equipmentList.update'), { variant: 'success' });
              updateEquipmentInList({
                ...equipment,
                activated
              });
            }
        ).catch(
        (error) => enqueueSnackbar(error?.message ?? error, { variant: 'error' })
    );
  }, [updateEquipmentInList]);

  return (
    <ToggleValidationButton
        name="activationToggle"
        value={activatedTool ? 'on' : 'off'}
        onChange={(e, value) => handleToggleChange(e, row, value)}
    />
  );
};

const getListHeaders: (
  updateEquipmentInList: (equipment: RefrigerationUnitSummaryItem) => void,
  editAction: (equipment: RefrigerationUnitSummaryItem) => void
) => HeaderType<RefrigerationUnitSummaryItem>[] = (
  updateEquipmentInList,
  editAction
) => [
  {
    name: 'brand',
    label: translate('common.brand'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.brand}
      </TableCell>
    )
  }, {
    name: 'model',
    label: translate('common.model'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.model}
      </TableCell>
    )
  }, {
    name: 'serialNumber',
    label: translate('common.serialNumber'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.serialNumber}
      </TableCell>
    )
  }, {
    name: 'fluid',
    label: translate('common.fluid'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <ListItemText
          primary={row.circuits && row.circuits[0]?.fluid?.name}
          secondary={`Quantité: ${row.fluidWeight || 0}${translate('common.weightUnit')}`}
        />
      </TableCell>
    )
  }, {
    name: 'nextControlDate',
    label: translate('common.nextControlDate'),
    template: row => (
      <TableCell key={shortid.generate()} style={{ color: getDateColorDisplay(row.nextControlDate) }}>
        {formatDateString(row.nextControlDate)}
      </TableCell>
    )
  }, {
    name: 'additionalData',
    label: translate('common.additionalData'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.internalIdentification}
      </TableCell>
    )
  }, {
    name: 'validated',
    label: translate('common.validated'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <FontAwesomeIcon color={row.validated ? 'var(--green)' : 'var(--red)'} icon={row.validated ? faCheck : faTimes} />
      </TableCell>
    )
  }, {
    name: 'actions',
    label: translate('common.actions'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <IconButton onClick={() => editAction(row)}>
          <FontAwesomeIcon color="var(--blue)" icon={faEdit} size="sm" />
        </IconButton>
      </TableCell>
    )
  },
  {
    name: 'activation',
    label: translate('common.listHeader.activation'),
    toolTipLabel: translate('common.tooltip.activation'),
    toolTipIcon: faInfoCircle,
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ActivationTemplate row={row} updateEquipmentInList={updateEquipmentInList}/>
        </div>
      </TableCell>
    )
  }
];

const EquipmentListHeader = styled.header`
  text-align: center;
  margin-bottom: 3rem;
  padding: 2rem;
  border-bottom: 1px solid #d3d2d6;

  h1 {
    margin: 1rem 0;
  }

  p {
    margin: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const EquipmentList = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { open, close } = useModal();
  const locationState: any = location.state;
  const historyFilter: FilterItem<RefrigerationUnitFilter>[] = (locationState && locationState.filters);
  const defaultFilters: FilterItem<RefrigerationUnitFilter>[] = [{
    key: 'activated',
    label: translate('common.activated'),
    booleanValue: true
  }];

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [equipmentList, setEquipmentList] = useState([]);
  const [equipmentOwner, setEquipmentOwner] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(historyFilter);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const { id: equipmentOwnerId } = useParams<any>();
  const filterKey = `${equipmentOwnerId}_equipmentList`;

  const getEquipmentOwnerDetail = useCallback(() => {
    if (equipmentOwnerId) {
      setIsLoadingDetail(true);

      EquipmentOwnersService.getEquipmentOwnerDetail(equipmentOwnerId)
        .then((response) => {
          setEquipmentOwner(response);
        }).finally(() => setIsLoadingDetail(false));
    }
  }, [equipmentOwnerId]);

  const getEquipmentList = useCallback(({ pageChange = false } = {}) => {
    setIsLoading(true);
    if (equipmentOwnerId) {
      EquipmentOwnersService.getEquipmentList(equipmentOwnerId, FilterHelper.buildFilterForm(filters, search), currentPage)
        .then((response) => {
          if (response) {
            let allEquipments = response.content;
            if (pageChange && currentPage > 1) {
              allEquipments = [].concat(...equipmentList, response.content);
            }
            setEquipmentList(allEquipments);
            setMaxPage(response.totalPages);
          }
        }).finally(() => setIsLoading(false));
    } else {
      history.push(ROUTES.ADMINISTRATION);
    }
  }, [search, filters, currentPage, equipmentList, equipmentOwnerId, history]);

  const reloadList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 1) getEquipmentList({ pageChange: false });
    else setCurrentPage(1);
  }, [getEquipmentList, currentPage]);

  useEffectAfterRender(() => {
    if (currentPage === 1) getEquipmentList({ pageChange: false });
    else setCurrentPage(1);
    // eslint-disable-next-line
  }, [filters, search]);

  useEffectAfterRender(() => {
    getEquipmentList({ pageChange: currentPage !== 1 });
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    getEquipmentOwnerDetail();
  }, [getEquipmentOwnerDetail]);

  const handleLoadMore = useCallback(() => !isLoading && setCurrentPage(currentPage + 1), [isLoading, currentPage]);

  const createEquipment = useCallback((form: RefrigerationUnitView) => {
    const formWithOwner: RefrigerationUnitView = {
      ...form,
      ownerEntityId: equipmentOwnerId
    };

    if (form.id) {
      return EquipmentsService.updateEquipment(form.id, formWithOwner).then(() => {
        close();
        enqueueSnackbar(translate('confirms.equipmentList.update'), { variant: 'success' });
        reloadList();
      }).catch((error) => enqueueSnackbar(error, { variant: 'error', autoHideDuration: 5000 }));
    }

    return EquipmentsService.createEquipment(formWithOwner).then(() => {
      close();
      enqueueSnackbar(translate('confirms.equipmentList.create'), { variant: 'success' });
      reloadList();
    }).catch((error) => enqueueSnackbar(error, { variant: 'error', autoHideDuration: 5000 }));
  }, [equipmentOwnerId, close, reloadList]);

  const updateEquipmentOwner = useCallback((form: EntityView) => EquipmentOwnersService.updateEquipmentOwner(form.id, form)
    .then(() => {
      enqueueSnackbar(translate('confirms.equipmentList.updateOwner'), { variant: 'success' });
      getEquipmentOwnerDetail();
      close();
    }), [getEquipmentOwnerDetail, close]);

  const exportEquipments = useCallback(() => {
      setIsExporting(true);
      EquipmentOwnersService.exportEquipmentsFromALegalEntity(equipmentOwnerId, FilterHelper.buildFilterForm(filters, search)).then((resp) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = DocumentHelper.getExcelWithBase64(resp);
        downloadLink.download = resp.name;
        downloadLink.click();
      }).catch(error => enqueueSnackbar(
        (error.message || error || translate('errors.export')).replace('<br />', '\n'),
        { variant: 'error', autoHideDuration: 5000 }
      ))
        .finally(() => setIsExporting(false));
    }, [equipmentOwnerId, filters, search]);

  const displayModalAddEquipment = useCallback((row) => {
    open({
      type: 'ADD_EQUIPMENT',
      onSubmit: createEquipment,
      equipmentId: row.id,
      withPicture: true
    });
  }, [createEquipment, open]);

  const displayModalAddEquipmentOwner = useCallback(() => open({
    type: 'ADD_EQUIPMENT_OWNER',
    onSubmit: updateEquipmentOwner,
    equipmentOwnerId
  }), [open, updateEquipmentOwner, equipmentOwnerId]);

  const EquipmentListTable = ({ listHeadersTable, withRowAction }) => (
    <GenericTable<EquipmentSummaryItem>
      dataCy="equipmentList"
      hasMore={currentPage < maxPage}
      headers={listHeadersTable}
      loadMore={handleLoadMore}
      rows={equipmentList}
      onRowClick={withRowAction && displayModalAddEquipment}
    />
  );

  const renderFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <EquipmentListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const renderButtonCreate = useCallback(() => (
    <div className="buttonsContainer">
      <ButtonBlue
        data-cy="createNewEquipment"
        data-tour="step-equipment-create"
        onClick={displayModalAddEquipment}
      >
        <FontAwesomeIcon icon={faAirConditioner} />
        {translate('button.createEquipment')}
      </ButtonBlue>
    </div>
  ), [displayModalAddEquipment]);

  const renderButtonExport = useCallback(() => (
    <div className="buttonsContainer" style={{ left: '0' }}>
      <ButtonBlue
        data-cy="exportEquipments"
        data-tour="step-equipments-export"
        disabled={isExporting || isLoading}
        onClick={exportEquipments}
      >
        <FontAwesomeIcon icon={isExporting ? faSpinner : faFileExport} spin={isExporting} />
        {translate('button.exportEquipments')}
      </ButtonBlue>
    </div>
  ), [exportEquipments, isExporting, isLoading]);

  const renderButtonCreateMobile = useCallback(() => (
    <ButtonFloating
      dataCy="createEquipment"
      onClick={displayModalAddEquipment}
    >
      <Fab aria-label={translate('button.createEquipment')} color="primary">
        <CustomIcon icon={<FontAwesomeIcon icon={faAirConditioner} />} marginLeft="0.6rem" />
      </Fab>
    </ButtonFloating>
  ), [displayModalAddEquipment]);

  const updateEquipmentInList: (equipment: RefrigerationUnitSummaryItem) => void = useCallback((equipmentUpdated) => {
    setEquipmentList(equipmentList.map(
        (e) => (e.id === equipmentUpdated.id ? equipmentUpdated : e)
    ));
  }, [equipmentList, setEquipmentList]);

  if (isLoadingDetail) return <SkeletonMain />;
  if (!isLoading && !equipmentOwner) return <TextError>{translate('errors.noEquipmentOwner')}</TextError>;

  return (
    <Wrapper>
      <EquipmentListHeader>
        <FontAwesomeIcon color="var(--turquoise)" icon={faUserTie} size="3x" />
        <TitlePage>
          {`${equipmentOwner.firstName} ${equipmentOwner.lastName || ''}`}
          <CustomTooltip
            color={equipmentOwner.validated ? 'var(--green)' : 'var(--red)'}
            icon={equipmentOwner.validated ? faCheck : faTimes}
            text={translate(equipmentOwner.validated ? 'pageEquipmentList.equipmentOwnerValidated' : 'pageEquipmentList.equipmentOwnerNotValidated')}
          />
        </TitlePage>
        {equipmentOwner.siret && (
          <Text>
            <FontAwesomeIcon icon={faTag} />
            {equipmentOwner.siret}
          </Text>
        )}
        {equipmentOwner.address && equipmentOwner.address.city && (
          <TextAddress address={equipmentOwner.address} withIcon />
        )}
        <ButtonBlue style={{ marginTop: '1rem' }} onClick={displayModalAddEquipmentOwner}>
          <FontAwesomeIcon icon={faUserEdit} size="lg" />
          {translate('button.edit')}
        </ButtonBlue>
      </EquipmentListHeader>
      <GenericListContainer>
        <SubtitlePage>
          {renderButtonExport()}
          {translate('pageEquipmentList.title')}
          <Media
            query="(min-width: 1081px)"
            render={renderButtonCreate}
          />
        </SubtitlePage>
        <GenericListFilters
          ComponentFilter={renderFilters}
          defaultFilters={defaultFilters}
          disabled={false}
          filterKey={filterKey}
          filters={filters}
          isVisible
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          withSidePanel
        />

        <div data-tour="step-admin-equipmentList">
          {!isLoading && equipmentList.length === 0
            ? (
              <FlexContainer alignItems="center" flexDirection="column">
                <TextError>{translate('errors.noEquipment')}</TextError>
                <ButtonBlue
                  data-cy="createEquipment"
                  onClick={displayModalAddEquipment}
                >
                  <FontAwesomeIcon icon={faAirConditioner} />
                  {translate('button.createEquipment')}
                </ButtonBlue>
              </FlexContainer>
            ) : (
              <Media query="(max-width: 768px)">
                {matches => (matches
                  ? <EquipmentListTable listHeadersTable={getListHeaders(updateEquipmentInList, displayModalAddEquipment).splice(0, 4)} withRowAction />
                  : <EquipmentListTable listHeadersTable={getListHeaders(updateEquipmentInList, displayModalAddEquipment)} withRowAction={false} />
                )}
              </Media>
            )
          }
        </div>

        {isLoading && <SkeletonTableSmall />}

        <Media
          query="(max-width: 1080px)"
          render={renderButtonCreateMobile}
        />
      </GenericListContainer>
    </Wrapper>
  );
});
