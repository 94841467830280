import { faUserEdit } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogContent, Grid, TextField } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { FormElement } from 'components';

import { useStores } from 'hooks';

import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useFormState } from 'react-use-form-state';

import styled from 'styled-components';
import { ADMIN_ROLES } from 'utils/constants';
import { FormHelper, translate, UserHelper } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';
import { CustomActionButtons } from './_CustomActionButtons';

const UpdateUserForm = styled.form`
  overflow: auto;
  
  .MuiToggleButton-root,
  .MuiToggleButton-root:hover {
    background-color: var(--white);
  }

  .MuiToggleButton-root.Mui-selected:first-child,
  .MuiToggleButton-root.Mui-selected:first-child:hover {
    background-color: var(--green);
  }

  .MuiToggleButton-root.Mui-selected:last-child,
  .MuiToggleButton-root.Mui-selected:last-child:hover {
    background-color: var(--red);
  }

  @media(min-width: 768px) {
    width: 680px;
  }
`;

export const UpdateUserModal = observer(({
  onClose, onConfirm, closeOnSubmit = true, defaultValues
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userStore } = useStores();
  const { scope } = userStore;

  const [formState, {
    text, email, tel
  }] = useFormState({
    accountId: defaultValues.accountId !== null ? defaultValues.accountId : null,
    accountActivated: defaultValues.accountActivated !== null ? defaultValues.accountActivated : true,
    login: defaultValues.login ?? '',
    firstName: defaultValues.person.firstName ?? '',
    lastName: defaultValues.person.lastName ?? '',
    attestationNumber: defaultValues.person.attestationNumber ?? '',
    firstContactEmail: defaultValues.person.contact.firstContactEmail ?? '',
    phoneNumber: defaultValues.person.contact.phoneNumber ?? '',
    occupation: defaultValues.person.occupation ?? '',
    address1: defaultValues.person.address.address1 ?? '',
    address2: defaultValues.person.address.address2 ?? '',
    postalCode: defaultValues.person.address.postalCode ?? '',
    city: defaultValues.person.address.city ?? '',
    country: UserHelper.getDefaultCountry(),
    email: defaultValues.person.contact.technicalEmail ?? '',
    contactEmails: defaultValues.person.contact.contactEmails ?? ''
  });

  const canAdministrate = () => !(!UserHelper.hasAccessRight(ADMIN_ROLES) || defaultValues.accountId === scope.accountId);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    setIsLoading(true);

    const emailErrors = formState.errors.email;

    if (!emailErrors) {
      onConfirm({
        accountActivated: formState.values.accountActivated,
        accountId: defaultValues.accountId,
        personId: defaultValues.personId,
        modelId: defaultValues.modelId,
        login: formState.values.login,
        person: {
          ...defaultValues.person,
          address: {
            address1: formState.values.address1,
            address2: formState.values.address2,
            postalCode: formState.values.postalCode,
            city: formState.values.city
          },
          birthDate: formState.values.birthDate,
          firstName: formState.values.firstName,
          lastName: formState.values.lastName,
          attestationNumber: formState.values.attestationNumber,
          contact: {
            technicalEmail: formState.values.email,
            contactEmails: formState.values.contactEmails,
            phoneNumber: formState.values.phoneNumber
          },
          mainCompany: defaultValues.mainCompany,
          occupation: formState.values.occupation
        }
      });
    }

    if (closeOnSubmit) {
      onClose();
    }
  }, [formState, closeOnSubmit, onClose, onConfirm, defaultValues]);

  return (
    <UpdateUserForm autoComplete="off" onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <Grid alignItems="center" container justifyContent="space-between">
          <div>
            <FontAwesomeIcon icon={faUserEdit} />
            {`${translate('modalUserUpdate.editUser')} : ${formState.values.login}`}
          </div>
        </Grid>
      </ModalHeader>

      <DialogContent>
        <Grid container direction="column">
          <FormElement label={translate('pageUserDetail.myInfos')}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.lastName != null}
                  label={translate('common.lastName')}
                  name="lastName"
                  required
                  {...text('lastName')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.firstName != null}
                  label={translate('common.firstName')}
                  name="firstName"
                  required
                  {...text('firstName')}
                />
              </Grid>
            </Grid>
            <Grid container>
              <TextField
                autoComplete="new-password"
                error={formState.errors.occupation != null}
                inputProps={{ maxLength: 200 }}
                label={translate('common.occupation')}
                name="occupation"
                {...text('occupation')}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                  autoComplete="new-password"
                  error={formState.errors.attestationNumber != null}
                  label={translate('common.capacityAttestationNumber')}
                  name="attestationNumber"
                  {...text('attestationNumber')}
              />
            </Grid>
          </FormElement>
          <FormElement label={translate('common.contact')}>
            <Grid item sm={12}>
              <TextField
                  autoComplete="new-password"
                  disabled={!defaultValues.canEditEmail}
                  error={formState.errors.contactEmails && formState.errors.contactEmails !== null}
                  helperText={formState.errors.contactEmails}
                  label={translate('common.contactEmails.modal')}
                  name="contactEmails"
                  {...text({
                    name: 'contactEmails',
                    // Additional validation for emails because useFormState validation stops at example@example
                    validate: value => FormHelper.validateEmails(value)
                  })}
              />
            </Grid>
            <Grid container spacing={2}>
              {formState.values.accountActivated && <Grid item sm={6} xs={12}>
                <TextField
                    autoComplete="new-password"
                    error={formState.errors.email != null}
                    helperText={formState.errors.email}
                    label={translate('common.userModal.technicalEmail')}
                    name="email"
                    required
                    type="email"
                    {...email({
                      name: 'email',
                      // Additional validation for emails because useFormState validation stops at example@example
                      validate: value => FormHelper.validateEmail(value)
                    })}
                />
              </Grid>}
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.phoneNumber != null}
                  label={translate('common.phone')}
                  name="phoneNumber"
                  {...tel('phoneNumber')}
                />
              </Grid>
            </Grid>
          </FormElement>
          <FormElement label={translate('common.address')}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.address1 != null}
                  label={translate('common.address')}
                  name="address1"
                  required={FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.postalCode, formState.values.city]
                  })}
                  {...text('address1')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.address2 != null}
                  label={translate('common.address2')}
                  name="address2"
                  {...text('address2')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.postalCode != null}
                  label={translate('common.postalCode')}
                  name="postalCode"
                  required={FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address1, formState.values.city]
                  })}
                  style={{ maxWidth: '100px' }}
                  {...text('postalCode')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.city != null}
                  label={translate('common.city')}
                  name="city"
                  required={FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address1, formState.values.postalCode]
                  })}
                  {...text('city')}
                />
              </Grid>
              {/* This input is only used for forcing disabling autocomplete in chrome browser */}
              <input name="fakeInputForForcingDisablingAutocompleteChrome4" style={{ display: 'none' }} type="text" />
            </Grid>
          </FormElement>

          {canAdministrate() && (
            <>
              <FormElement label={translate('common.userAccount')}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    {canAdministrate() && (
                    <div>
                      <ToggleButtonGroup
                        aria-label={translate('modalUserUpdate.userStatus')}
                        exclusive
                        size="small"
                        value={formState.values.accountActivated}
                        onChange={(event, value) => {
                          if (value === null) return;
                          formState.setField('accountActivated', value);
                        }}
                      >
                        <ToggleButton aria-label={translate('common.activated')} value>{translate('common.activated')}</ToggleButton>
                        <ToggleButton aria-label={translate('common.deactivated')} value={false}>{translate('common.deactivated')}</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    {formState.values.accountActivated && (
                    <TextField
                      autoComplete="new-password"
                      disabled={formState.values.accountId != null}
                      error={formState.errors.login != null}
                      label={translate('common.login')}
                      name="login"
                      required
                      {...text('login')}
                    />
                    )}
                  </Grid>
                </Grid>
              </FormElement>
            </>
          )}
        </Grid>
      </DialogContent>

      <CustomActionButtons
        isLoading={isLoading}
        onClose={onClose}
      />
    </UpdateUserForm>
  );
});
