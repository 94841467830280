import { EquipmentSummary, EquipmentSummaryItem } from 'models';
import { RefrigerationUnitView } from 'models/detail/RefrigerationUnitView';
import { EquipmentFilter } from 'models/request/list/EquipmentFilter';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';
import { RefrigerationUnitFilter } from '../models/request/list/RefrigerationUnitFilter';

const equipmentsUrl = `${API_URL}equipments`;

// GET
const getEquipmentDetail: (
  id: string
) => Promise<RefrigerationUnitView> = (
  id
) => RequestHelper.GET(`${equipmentsUrl}/${id}`);

const getInstallationTypes: () => Promise<SelectItem[]> = () => RequestHelper.GET(`${equipmentsUrl}/installation-types/options`);

// POST
const getEquipmentList: (
  params: EquipmentFilter
) => Promise<EquipmentSummaryItem[]> = async (
  params
) => {
  // Since the equipment options can be quite long to load, the request is done by blocks
  let isLast = false;
  let page = 1;
  const size = 500;
  const fullList: EquipmentSummaryItem[] = [];
  while (!isLast) {
    /* eslint-disable */
    await RequestHelper.POST(`${equipmentsUrl}/extended-options?page=${page}&size=${size}`, params)
      .then((response: EquipmentSummary) => {
        fullList.push(...response.content);
        isLast = response.last;
      });
    page++;
  }
  return Promise.resolve(fullList);
};

const importEquipments = document => RequestHelper.POST(`${equipmentsUrl}/import`, document);

const importEquipmentOwners = document => RequestHelper.POST(`${equipmentsUrl}/import-owners`, document);

const createEquipment: (
  equipmentView: RefrigerationUnitView
) => Promise<void> = (
  equipmentView
) => RequestHelper.POST(`${equipmentsUrl}`, equipmentView);

const updateEquipment: (
  equipmentId: string,
  equipmentView: RefrigerationUnitView
) => Promise<void> = (
  equipmentId,
  equipmentView
) => RequestHelper.PUT(`${equipmentsUrl}/${equipmentId}`, equipmentView);

const mergeEquipment = (hashIdEdited, hashIdMerged) => RequestHelper.POST(`${equipmentsUrl}/${hashIdEdited}/fuse`, { otherId: hashIdMerged });

const exportEquipments = (filter: RefrigerationUnitFilter) => RequestHelper.POST(`${equipmentsUrl}/export`, filter);

const exportAsyncEquipments = (filter: RefrigerationUnitFilter, signal: AbortSignal) => RequestHelper.POST(`${equipmentsUrl}/notif-export`, filter, undefined, signal);

const toggleActivation = (equipmentId: string,) => RequestHelper.PUT(`${equipmentsUrl}/${equipmentId}/toggle-activation`);

export const EquipmentsService = {
  getEquipmentList,
  getEquipmentDetail,
  getInstallationTypes,
  createEquipment,
  importEquipments,
  importEquipmentOwners,
  updateEquipment,
  mergeEquipment,
  exportEquipments,
  exportAsyncEquipments,
  toggleActivation
};