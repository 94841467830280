import { UserView } from 'models';
import { RoleSelectItem } from 'models/detail/RoleSelectItem';
import { ScopeView } from 'models/detail/ScopeView';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';
import { StructureLevelOption } from 'utils/types/StructureLevelOption';
import { AccessibleRoleByStructureLevelView } from '../models/detail/AccessibleRoleByStructureLevelView';

const usersUrl = `${API_URL}user-accounts`;

// GET
const getCurrentUser: () => Promise<UserView> = () => RequestHelper.GET(`${usersUrl}/current`);

const getCurrentLevel: () => Promise<StructureLevelOption> = () => RequestHelper.GET(`${usersUrl}/current/current-level`);

const getLevelOptions: () => Promise<StructureLevelOption[]> = () => RequestHelper.GET(`${usersUrl}/current/accessible-levels`);

const getUser: (id: string) => Promise<UserView> = id => RequestHelper.GET(`${usersUrl}/${id}`);
const getCurrentScope: () => Promise<ScopeView> = () => RequestHelper.GET(`${usersUrl}/current/scope`);

const getAccessibleRolesWithStructureLevel: (companyId: string) => Promise<AccessibleRoleByStructureLevelView[]> = (companyId) => RequestHelper.GET(
    `${usersUrl}/${companyId}/rolesByStructureLevel`
);

const getApplicationCount: () => Promise<{ currentUse: number; maximumAllowed: number }> = () => RequestHelper.GET(`${usersUrl}/application/count`);

const getUserConfigsDeviceId: () => Promise<string> = () => RequestHelper.GET(`${usersUrl}/current/device-id`);

const getCompanyChildrenOptions: () => Promise<SelectItem[]> = () => RequestHelper.GET(`${usersUrl}/current/company/children-options`);

const resetPassword = () => RequestHelper.GET(`${usersUrl}/resetPassword`);

// POST
const getList: (
  params: any,
  page : number
) => Promise<any> = (
  params = {},
  page = 1
) => RequestHelper.POST(`${usersUrl}/filter?page=${page}`, params);

const createUser: (user: UserView) => Promise<UserView> = user => RequestHelper.POST(`${usersUrl}`, user);

// const importUsers = document => RequestHelper.POST(`${usersUrl}/import`, document);

// PUT
const updateUser: (id: string, user: UserView) => Promise<any> = (id, user) => RequestHelper.PUT(`${usersUrl}/${id}`, user);

const updateLocale: (locale: string) => Promise<any> = locale => RequestHelper.PUT(`${usersUrl}/locale?locale=${locale}`);

const updateCountry: (country: string) => Promise<any> = country => RequestHelper.PUT(`${usersUrl}/country?country=${country}`);

const updateRoles: (id: string, roles: RoleSelectItem[]) => Promise<any> = (id, roles) => RequestHelper.PUT(`${usersUrl}/${id}/roles`, roles);
const setUserConfigsDeviceId: (deviceId: string) => Promise<any> = deviceId => RequestHelper.PUT(`${usersUrl}/current/device-id`, { deviceId });

// PATCH
const updateLevel: (level: StructureLevelOption) => Promise<any> = level => RequestHelper.PATCH(`${usersUrl}/current/level`, level);

export const UsersService = {
  getCurrentUser,
  getCurrentLevel,
  getUser,
  getList,
  getApplicationCount,
  getLevelOptions,
  getUserConfigsDeviceId,
  getCompanyChildrenOptions,
  updateLevel,
  createUser,
  updateUser,
  updateLocale,
  updateCountry,
  setUserConfigsDeviceId,
  updateRoles,
  getCurrentScope,
  resetPassword,
  getAccessibleRolesWithStructureLevel
};
