import {
  faBuilding,
  faCheck, faHome, faPenNib, faSitemap, faStoreAlt, faTasks, faTimes, faUserCheck, faUserTie, IconDefinition
} from '@fortawesome/pro-regular-svg-icons';

const getEnv = key => {
  const currentWindow: any = window;
  // eslint-disable-next-line no-underscore-dangle
  return ((currentWindow._env && currentWindow._env[`REACT_APP_${key}`]) || process.env[`REACT_APP_${key}`] || '');
};

export const API_URL = getEnv('API_URL');
export const API_URL_DATAFLUIDES = getEnv('API_URL_DATAFLUIDES');

export const INSEE_API_TOKEN = getEnv('INSEE_API_TOKEN');
export const MAPBOX_TOKEN = getEnv('MAPBOX_TOKEN');

export const KEYCLOAK_REALM = getEnv('KEYCLOAK_REALM');
export const KEYCLOAK_AUTH_SERVER_URL = getEnv('KEYCLOAK_AUTH_SERVER_URL');
export const KEYCLOAK_RESOURCE = getEnv('KEYCLOAK_RESOURCE');

export const DATATECNEA_URL = getEnv('DATATECNEA_URL');

export const RGPD_EMAIL = 'rgpd@tecnea.fr';
export const ASSISTANCE_EMAIL = 'databilan@tecnea.fr';

export const APP_VERSION = getEnv('VERSION');
export const ENVIRONMENT = getEnv('ENVIRONMENT');
export const CREATED = getEnv('CREATED');
export const REVISION = getEnv('REVISION');
export const GA4_TAG_ID = getEnv('TAG_MANAGER_ID');

export const APP_NAME = 'databilan';

export const TOOLS_MONTHS_EXPIRATION_MAX = 12;
export const TOOLS_MONTHS_EXPIRATION = 10;
export const EQUIPMENT_MONTHS_NEXT_CONTROL = 2;

export const ROUTES = {
  ADMINISTRATION: '/administration/detenteurs',
  ADMINISTRATION_TOOLS: '/administration/outillages',
  ADMINISTRATION_SUPPLIERS_RECIPIENTS: '/administration/fournisseurs-clients',
  ADMINISTRATION_USERS: '/administration/utilisateurs',
  ADMINISTRATION_INSTITUTIONS: '/administration/etablissements',
  ADMINISTRATION_TRACK_DECHETS: '/administration/track-dechets',
  STOCKS: '/stocks/stats',
  STOCKS_INITIALISATION: '/stocks/initialisation',
  STOCKS_PENDING: '/stocks/confirmer',
  STOCKS_FLUIDS: '/stocks/fluides',
  STOCKS_CONTAINERS: '/stocks/bouteilles',
  STOCKS_VALIDATION: '/stocks/validation',
  STOCKS_BALANCE: '/stocks/bilan',
  EQUIPEMENT_OWNER: '/detenteur/:id',
  CONTAINERS: {
    LIST: '/bouteilles',
    DETAIL: '/bouteilles/:id'
  },
  HOME: '/accueil',
  FLUID: {
    DETAIL: '/mouvement-de-fluides/:id'
  },
  INTERVENTION: {
    LIST: '/interventions',
    DETAIL: '/interventions/:id/detail',
    PLAN: '/interventions/:id/plan',
    FORM: '/interventions/:id/inspection'
  },
  USERS: {
    DETAIL: '/utilisateurs/:id'
  },
  RGPD: '/mentions-legales',
  COOKIES: '/gestion-cookies'
};

export const APPLICATION_ROLES = {
  APPOINTMENT_MANAGER: 'DATABILAN_APPOINTMENT_MANAGER',
  ADMIN: 'DATABILAN_ADMIN',
  TECNEA_ADMIN: 'DATABILAN_TECNEA_ADMIN',
  INTERVENTION_MANAGER: 'DATABILAN_INTERVENTION_MANAGER',
  STOCK_MANAGER: 'DATABILAN_STOCK_MANAGER',
  SUPER_ADMIN: 'DATABILAN_SUPER_ADMIN',
  OPERATOR: 'DATABILAN_OPERATOR',
  DISTRIBUTOR_GENERAL_MANAGER: 'DATABILAN_DISTRIBUTOR_GENERAL_MANAGER',
  DISTRIBUTOR_STOCK_MANAGER: 'DATABILAN_DISTRIBUTOR_STOCK_MANAGER',
  DISTRIBUTOR_ADMIN: 'DATABILAN_DISTRIBUTOR_ADMIN'
};

export const ADMIN_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.DISTRIBUTOR_ADMIN
];

export const INTERVENTION_ACCESS_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.INTERVENTION_MANAGER,
  APPLICATION_ROLES.APPOINTMENT_MANAGER,
  APPLICATION_ROLES.OPERATOR
];

export const NOT_DISTRIBUTOR_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.STOCK_MANAGER,
  APPLICATION_ROLES.INTERVENTION_MANAGER,
  APPLICATION_ROLES.APPOINTMENT_MANAGER,
  APPLICATION_ROLES.OPERATOR
];

export const DISTRIBUTOR_ROLES = [
  APPLICATION_ROLES.DISTRIBUTOR_GENERAL_MANAGER,
  APPLICATION_ROLES.DISTRIBUTOR_STOCK_MANAGER,
  APPLICATION_ROLES.DISTRIBUTOR_ADMIN
];

export const STOCK_ROLES_WITH_INTERVENTION_MANAGER = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.INTERVENTION_MANAGER,
  APPLICATION_ROLES.STOCK_MANAGER,
  APPLICATION_ROLES.DISTRIBUTOR_ADMIN,
  APPLICATION_ROLES.DISTRIBUTOR_GENERAL_MANAGER,
  APPLICATION_ROLES.DISTRIBUTOR_STOCK_MANAGER
];

export const STOCK_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.STOCK_MANAGER,
  APPLICATION_ROLES.DISTRIBUTOR_ADMIN,
  APPLICATION_ROLES.DISTRIBUTOR_GENERAL_MANAGER,
  APPLICATION_ROLES.DISTRIBUTOR_STOCK_MANAGER
];

export const RECEPTION_ROLES = [
  APPLICATION_ROLES.DISTRIBUTOR_ADMIN,
  APPLICATION_ROLES.DISTRIBUTOR_GENERAL_MANAGER,
  APPLICATION_ROLES.DISTRIBUTOR_STOCK_MANAGER
];

export const ACCESS_TO_RECIPIENTS_ROLES = [
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.DISTRIBUTOR_ADMIN
];

export const FLUID_BALANCE_SYNCHRONIZE_ROLES = [
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.INTERVENTION_MANAGER
];

export const COMPANIES = {
  PETIT_FORESTIER: '5ROkEmY_2VfTE+HbzhlsibCeliBMJzD8i+BRqh5kasJx2wgWzdDF20HwbNqBvS4EBfLfBeKhKoQ=',
  SUPRALOG: 'aT3vBAb0NZi33Jg4WI0pJV5XSi_Z26Pios20DQ8pl7NelqXx7tEWVk8diO4Nv4LhIMIvrmhvPBg=',
  CEMAFROID: 'u6HiY+GOhHu33Jg4WI0pJV5XSi_Z26Pios20DQ8pl7NelqXx7tEWVk8diO4Nv4LhIMIvrmhvPBg=',
  AZUR_TRUCKS: 'y2b7OciwhjciI276w4jRApxbLJK2Q2r_3c4r7LSYSF8OvR9U5ebGQ4Z2Nt4nzdU77oh1GDpfp9A=',
  EIFFAGE: 'slqKBNEUHZBnzO5M8Skd3VegIqHLe12HVoSdKA_WqpUkAj1Fr1S1Ptqme5nVaHEd5Whju5_yDFo='
};

export const MENU_ITEMS = [
  {
    title: 'menu.home',
    icon: faHome,
    isFontIcon: false,
    url: ROUTES.HOME
  },
  {
    title: 'menu.interventions',
    icon: 'icon-intervention',
    url: ROUTES.INTERVENTION.LIST,
    roles: [APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.INTERVENTION_MANAGER, APPLICATION_ROLES.APPOINTMENT_MANAGER, APPLICATION_ROLES.OPERATOR]
  },
  {
    title: 'menu.stockManagement',
    icon: 'icon-stocks',
    url: ROUTES.STOCKS
  }
];

export const PAGE_ADMIN_TABS = {
  EQUIPMENT_OWNER: {
    tabIndex: 0
  },
  TOOLS: {
    tabIndex: 1
  },
  USERS: {
    tabIndex: 2
  },
  INSTITUTIONS: {
    tabIndex: 3
  }
};

export const PAGE_STOCKS_TABS = {
  DASHBOARD: {
    tabIndex: 0
  },
  FLUID_MOVEMENTS: {
    tabIndex: 2
  },
  BOTTLES: {
    tabIndex: 3
  },
  BALANCES: {
    tabIndex: 5
  },
  VALIDATIONS: {
    tabIndex: 4
  }
};

type InterventionStatus = 'PENDING' | 'READY' | 'ONGOING' | 'TO_VALIDATE' |'TO_SIGN' | 'FINISHED' | 'CANCELED' | 'TO_SYNCHRONIZE';

export type StatusList = {
  [key in InterventionStatus] : {
    color: string,
    icon: IconDefinition,
    key: string,
    label: string
  };
}

export const INTERVENTION_STATUS: StatusList = {
  PENDING: {
    icon: faUserTie,
    color: 'var(--turquoise)',
    key: 'PENDING',
    label: 'interventionStatus.PENDING'
  },
  READY: {
    icon: faUserTie,
    color: 'var(--green-dark)',
    key: 'READY',
    label: 'interventionStatus.READY'
  },
  ONGOING: {
    icon: faTasks,
    color: 'var(--orange)',
    key: 'ONGOING',
    label: 'interventionStatus.ONGOING'
  },
  TO_VALIDATE: {
    icon: faUserCheck,
    color: 'var(--orange)',
    key: 'TO_VALIDATE',
    label: 'interventionStatus.TO_VALIDATE'
  },
  TO_SIGN: {
    icon: faPenNib,
    color: 'var(--turquoise)',
    key: 'TO_SIGN',
    label: 'interventionStatus.TO_SIGN'
  },
  FINISHED: {
    icon: faCheck,
    color: 'var(--green)',
    key: 'FINISHED',
    label: 'interventionStatus.FINISHED'
  },
  CANCELED: {
    icon: faTimes,
    color: 'var(--red)',
    key: 'CANCELED',
    label: 'interventionStatus.CANCELED'
  },
  TO_SYNCHRONIZE: {
    icon: faTasks,
    color: 'var(--orange)',
    key: 'TO_SYNCHRONIZE',
    label: 'interventionStatus.TO_SYNCHRONIZE'
  }
};

export const INTERVENTION_FORM_STEPS = [
  {
    id: 1,
    label: 'common.ownerAndEquipment',
    title: 'interventionFormSteps.ownerAndEquipmentTitle',
    tooltip: ''
  },
  {
    id: 2,
    label: 'common.interventionType',
    title: 'interventionFormSteps.typeTitle',
    tooltip: 'interventionFormSteps.typeTooltip'
  },
  {
    id: 3,
    label: 'common.control',
    title: 'interventionFormSteps.controlTitle',
    tooltip: 'interventionFormSteps.controlTooltip'
  },
  {
    id: 4,
    label: 'common.leaks',
    title: 'interventionFormSteps.leaksTitle',
    tooltip: 'interventionFormSteps.leaksTooltip'
  },
  {
    id: 5,
    label: 'common.fluids',
    title: 'interventionFormSteps.fluidsTitle',
    tooltip: 'interventionFormSteps.fluidsTooltip'
  },
  {
    id: 6,
    label: 'common.sign',
    title: 'interventionFormSteps.signaturesTitle',
    tooltip: 'interventionFormSteps.signaturesTooltip'
  }
];

export const FLUID_MANIPULATION_LIST_INPUT = [
  {
    value: 'NEW_FLUID_INPUT',
    label: 'modalFluidManipulation.fluidNew'
  },
  {
    value: 'RECYCLED_FLUID_INPUT',
    label: 'modalFluidManipulation.fluidRecycled'
  },
  {
    value: 'REGENERATED_FLUID_INPUT',
    label: 'modalFluidManipulation.fluidRegenerated'
  }
];

export const FLUID_MANIPULATION_LIST_OUTPUT = [
  {
    value: 'FLUID_FOR_TREATMENT_OUTPUT',
    label: 'modalFluidManipulation.fluidForTreatment'
  },
  {
    value: 'FLUID_FOR_REUSE_OUTPUT',
    label: 'modalFluidManipulation.fluidForReuse'
  }
];

export const BOTTLE_TYPES = [
  {
    key: 'NEW_BOTTLE'
  },
  {
    key: 'RETRIEVAL_BOTTLE'
  },
  {
    key: 'TRANSFER_BOTTLE'
  }
];

export const BOTTLE_TYPES_FILTERS = [
  {
    key: 'NEW_OR_REGENERATED'
  },
  {
    key: 'RETRIEVAL_BOTTLE'
  },
  {
    key: 'TRANSFER_BOTTLE'
  }
];

export const VALIDATED_TYPES = [
  {
    key: 'validated',
    value: true
  },
  {
    key: 'unvalidated',
    value: false
  }
];

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

export const PERIODS = [
  'week',
  'month',
  'year'
];

export const LEGAL_ENTITY_TYPES = {
  SUPPLIER: 'SUPPLIER',
  RECIPIENT: 'RECIPIENT',
  EQUIPMENT_OWNER: 'EQUIPMENT_OWNER',
  OPERATOR: 'OPERATOR'
};

export const TOOL_TYPES = {
  LEAK_DETECTOR: 'LEAK_DETECTOR',
  SCALE: 'SCALE'
};

export const COUNTRY_CODE = {
  FR: 'fr',
  GB: 'gb',
  IT: 'it',
  CA: 'ca'
};

export const getLegalEntityTypeTranslationKey = entityType => `legalEntityTypes.${entityType.toLowerCase().replace(/ /g, '_')}`;

export const STRUCTURE_TYPE = {
  COMPANY: 'COMPANY',
  PARENT_COMPANY: 'PARENT_COMPANY',
  INSTITUTION_MODEL: 'INSTITUTION_MODEL',
  INSTITUTION_GROUP: 'INSTITUTION_GROUP'
};

export const STRUCTURE_LEVEL_ICON = {
  COMPANY: faStoreAlt,
  PARENT_COMPANY: faStoreAlt,
  INSTITUTION_MODEL: faBuilding,
  INSTITUTION_GROUP: faSitemap
};
