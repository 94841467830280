import { API_URL_DATAFLUIDES } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const fluidBalancesUrl = `${API_URL_DATAFLUIDES}fluid-balances`;

const isEditableFluidBalanceFromInstitutionModelId = ((institutionModelId, year) => {
  const entries = [];
  return RequestHelper.POST(
    `${fluidBalancesUrl}/editable`,
    { institutionModelId, year, entries }
  );
});

const getInstitutionIdsWithEditableFluidBalancesForYear = ((year) => RequestHelper.GET(
    `${fluidBalancesUrl}/filter-editable-institution?year=${year}`
  )
);

const synchronizeFromDatabilan = (databilanFluidBalanceView) => RequestHelper.PUT(
    `${fluidBalancesUrl}/synchronize-from-databilan`,
    databilanFluidBalanceView
  );

export const DatafluidesFluidBalanceService = {
  isEditableFluidBalanceFromInstitutionModelId,
  getInstitutionIdsWithEditableFluidBalancesForYear,
  synchronizeFromDatabilan
};
